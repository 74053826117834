<template>
    <template v-if="bilingue">
        <section class="aa-contenedor-basico fade-in">
            <div class="aa-contenedor-basico-portada">
                <img src="../../../assets/img/fotos/conversacion.jpg" alt="Recursos auxiliar conversación" class="portada" v-on:load="onLoaded" v-show="loaded" />
                <div style="width:100%; height: 327px" v-show="!loaded">
                    <LoadingVue />
                </div>
            </div>

            <div class="curso-auxiliar">
                <h2 class="aa-text-h2"> Recursos {{bilingue.nombre}} </h2>

                <div class="clases-recursos" v-for="(recurso, index) in bilingue.recursos" :key="index">
                    <div class="rec-clas aa-text-h2-5 fw-600 px-2 rec-clas text-start">
                        {{index}}
                    </div>
                    <div class="asignaturas" v-for="({asignatura, ficheros}, idx) in recurso" :key="idx">
                        <div class="asignatura">{{asignatura}}</div>
                        <DescargaDocumento :documentos="ficheros" :titulo="'Documentos'" class="mb-3" />
                    </div>
                </div>
            </div>
        </section>
    </template>
</template>

<script>
import { defineAsyncComponent, watch } from '@vue/runtime-core'
import LoadingVue from '../components/Loading.vue'

import useBilin from '../composables/useBilin'
import { useRoute } from 'vue-router'


export default {
    components: {  
        LoadingVue,
        DescargaDocumento: defineAsyncComponent( () => import('../components/Area/DescargaDocumento.vue')),
    },
    setup() {
        const route = useRoute()
        const { isLoadingBil, loaded, bilingue, getRecursos } = useBilin()
        getRecursos(route.params.id)
        
        watch(
            () => route.params.id,
            () => getRecursos(route.params.id),
        )
        
        return {
            isLoadingBil,
            bilingue,
            loaded,
            onLoaded: () => loaded.value = true,
        }
    }
}
</script>

<style lang="sass" scoped>
.curso-auxiliar
    display: flex
    flex-direction: column

    .clases-recursos
        .rec-clas
            position: relative
            &::before
                content: " "
                width: 31px
                height: 4px
                position: absolute
                left: 0
                top: 23px
                background-color: #18d580
                z-index: -1
        .asignaturas
            .asignatura
                margin: 15px 0px
                color: #18d580
                font-weight: 800
                font-size: 1.2rem


    

</style>